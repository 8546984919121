<template>
	<div class="playlists-selection" v-if="playlists" ref="playlists-selection">
		<ul>
			<li 
				v-for="(playlist, idx) in playlists" 
				:key="idx" 
				@click="setVisiblePlaylist(idx)"
				:class="{ shown: isVisiblePlaylist(idx), active: isActivePlaylist(idx) }"	
				:aria-label="label(playlist)"
				:title="label(playlist)"
				
			>{{ playlist.name }}<button 
									type="button" 
									:aria-label="playlistBtLabel(playlist)" 
									:title="playlistBtLabel(playlist)"  
									@click="setActivePlaylistIndex(idx)"><i class="fa fa-solid fa-play"></i></button></li>
		</ul>
	</div>	
</template>
<script>
export default {
	props: {
	playlists: {
		Type: Array,
		default: []
	},
	visiblePlaylistIndex: {
		type: Number,
		default: 0
	},
	activePlaylistIndex: {
		type: Number,
		default: 0
	}
},
	emits: ['setVisiblePlaylistIndex', 'setActivePlaylistIndex'],
	
	methods: {
		playlistBtLabel(playlist) {
			return `jouer la playlist des ${playlist.name}`;
		},
		label(playlist) {
			return `voir la liste des ${playlist.name}`;
		},
		setVisiblePlaylist(index) {
			this.$emit("setVisiblePlaylistIndex", index);
		},

		setActivePlaylistIndex(index) {
			this.$emit("setActivePlaylistIndex", index);
		},

		isVisiblePlaylist(idx) {
			return this.visiblePlaylistIndex === idx;
		}, 

		isActivePlaylist(idx) {
			return this.activePlaylistIndex === idx;
		}
	}
}
</script>
