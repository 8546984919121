<template>
	<div 
		class="progress-bar-container"
		@click="seek"
		@keydown="handleKeydown"
		role="slider"
		aria-valuemin="0"
		:aria-valuenow="progress"
		aria-valuemax="100"
		tabindex="0"
		title="déplacer la tête de lecture"
	>
		<div class="progress-bar" :style="{ width: progress + '%' }"></div>
	</div>
</template>

<script>
export default {
	props: {
		progress: {
			type: Number,
			default: 0
		}
	},
	emits: ['seek'],
	methods: {
		seek(event) {
			const bounds = this.$el.getBoundingClientRect();
			const x = event.clientX - bounds.left;
			let percentage = Math.min((x / bounds.width) * 100, 100);
			this.$emit('seek', percentage);
		},

		handleKeydown(event) {
			if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
				let newProgress = this.progress;

				switch(event.key) {
					case 'ArrowRight':
						newProgress = Math.min(100, this.progress + 4);
						break;

					case 'ArrowLeft':
						newProgress  = Math.max(0, this.progress - 4);
						break;

					default:
						return false;
				}

				this.$emit('seek', newProgress);
				event.preventDefault();
			}
		}
	}
};
</script>

