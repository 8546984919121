<template>
	<div class="tracklist" ref="tracklist">
		<ul>
			<li v-for="track in playlist.tracks" :key="track.id">
				<button
					type="button" 
					role="button" 
					:title="`lancer la lecture de ${track.name}`"
					:aria-pressed="isTrackActive(track.id)"
					:class="isTrackActiveClass(track.id)" 
					@click="setTrack(track.id)">
					<span class="track-title">{{ track.name }}</span>
					<span class="track-artist">{{ track.performer }}</span>
				</button>
			</li>
		</ul>
	</div>	
</template>
<script>

export default {
	props: {
		playlist: {
			Type: Array,
			default: []
		},
		activeTrackId: {
			type: Number,
			default: 0
		}
	},
	emits: ['setActiveTrackById'],
	mounted() {
		this.adjustStylesheet();
	},
	methods: {
		isTrackActive(id) {
			return this.activeTrackId === id;
		},
		isTrackActiveClass(id) {
			return this.isTrackActive(id) ? "current" : null;
		},
		setTrack(id) {
			this.$emit("setActiveTrackById", id);
		},



		adjustStylesheet() {
			this.$nextTick(() => {

				let reservedHeight = 24;

				reservedHeight += this.$parent.$refs["main-header"].offsetHeight;
				reservedHeight += this.$parent.$refs.playlistMenu.$refs["playlists-selection"].offsetHeight;
				reservedHeight += this.$parent.$refs["main-footer"].offsetHeight;

				const viewportHeight = window.innerHeight;
				const tracklistHeight = viewportHeight - reservedHeight;
				
				this.$refs["tracklist"].style.height = tracklistHeight+"px";
			});
		}

	}
}
</script>
