<template>
	<div class="media-volume-control">

		<div class="volume-slider-placeholder" :class="{active: !isMuted, visible: isVolumeSliderVisible}">
			<div 
				class="volume-slider-container" 
				@click="setVolume"
				@keydown="handleKeydown"
				role="slider"
				aria-valuemin="0"
				aria-valuemax="100"
				:aria-valuenow="volume"
				tabindex="0"
				aria-label="Volume control"
			>
				<div class="volume-slider" :style="{ width: volume + '%' }"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		volume: {
			type: Number,
			default: 50 // Le volume initial est réglé à 50%
		},
		isMuted: {
			type: Boolean,
			default: false 
		}
	},
	data() {
		return {
			isVolumeSliderVisible: false,
		}
	},
	emits: ['changeVolume'],
	methods: {
		setVolume(event) {
			const bounds = this.$el.getBoundingClientRect();
			const diff = event.clientX - bounds.left;
			const newVolume = (diff / bounds.width) * 100;
			this.$emit('changeVolume', Math.round(newVolume));
		},

		handleKeydown(event) {
			if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
				let newVolume = this.volume;

				switch (event.key) {
					case 'ArrowRight':
						newVolume = Math.min(100, newVolume + 5);
						break;
					case 'ArrowLeft':
						newVolume = Math.max(0, newVolume - 5);
						break;
				}

				this.$emit('changeVolume', newVolume);
				event.preventDefault();
			}
		}
	},
};
</script>

