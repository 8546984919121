<template>
	<div class="media-controls">

		<button type="button" class="bt-media bt-playlist-toggle" @click="playlists" :aria-pressed="isPlaylistOpen" :aria-label="playlistLabel" :title="playlistLabel" :class="{active: isPlaylistOpen}"><i class="fa-solid fa-list"></i></button>
		<button type="button" class="bt-media bt-previous-track" @click="prev" aria-label="morceau précédent" title="morceau précédent"><i class="fa-solid fa-backward-step"></i></button>
		<button type="button" class="bt-media bt-play-pause" @click="play" :aria-pressed="isPlaying"  :aria-label="playingLabel" :title="playingLabel"  :class="{active: isPlaying}">
			<i class="fa-solid fa-pause" v-if="isPlaying"></i>
			<i class="fa-solid fa-play" v-else></i>
		</button>
		<button type="button" class="bt-media bt-next-track" @click="next" aria-label="morceau suivant" title="morceau suivant"><i class="fa-solid fa-forward-step"></i></button>
		<button type="button" class="bt-media bt-loop-toggle" @click="loop" :aria-pressed="isLooped" :aria-label="loopLabel" :title="loopLabel" :class="{active: isLooped}"><i class="fa-solid fa-repeat"></i></button>
		<button type="button" class="bt-media bt-mute-toggle" @click="mute" :aria-pressed="isMuted" :aria-label="muteLabel" :title="muteLabel" :class="{active: isMuted}">
			<i class="fa-solid fa-volume-xmark" v-if="isMuted"></i>
			<i class="fa fa-volume-up" v-else></i>
		</button>
		<media-volume-control
			:volume="volume"
			:isMuted="isMuted"
			@changeVolume="changeVolume"
			@mute="mute" 
		/>
	</div>
</template>
<script>
import MediaVolumeControl from "./MediaVolumeControl.vue";

export default {
	components: {
		MediaVolumeControl
	},
	props: {
		isPlaying: {
			type: Boolean,
			default: false
		},
		isRandom: {
			type: Boolean,
			default: false
		},
		isMuted: {
			type: Boolean,
			default: false
		},
		isLooped: {
			type: Boolean,
			default: false
		},
		isPlaylistOpen: {
			type: Boolean,
			default: false
		},
		volume: {
			type: Number,
			default: 50
		},
	},
	emits: ['play', 'next', 'prev', 'random', 'mute', 'loop', 'playlists', 'changeVolume'],
	computed: {
		muteLabel() {
			return this.isMuted ? 'Remettre le son' : 'Couper le son' ;
		},
		loopLabel() {
			return this.isLooped ? 'Désactiver la lecture en boucle' : 'Mettre la lecture en boucle';
		},
		playlistLabel() {
			return this.isPlaylistOpen? 'Cacher la liste des morceaux' : 'Afficher la liste des morceaux';
		},
		randomLabel() {
			return this.isRandom? 'Désactiver la lecture aléatoire' : 'Activer la lecture aléatoire';
		},
		nextLabel() {
			return 'Suivant';
		},
		prevLabel() {
			return 'Précédent';
		},
		playingLabel() {
			return this.isPlaying ? 'Pause' : 'Lecture';
		},
	},
	methods: {
		play() {
			this.$emit('play');
		},
		next() {
			this.$emit('next');
		},
		prev() {
			this.$emit('prev');
		},
		random() {
			this.$emit('random');
		},
		mute() {
			this.$emit('mute');
		},
		loop() {
			this.$emit('loop');
		},
		playlists() {
			this.$emit("playlists");
		},
		changeVolume(volume) {
			this.$emit('changeVolume', volume);
		},
	}
};
</script>

