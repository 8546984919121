<template>
	<MediaPlayer />
</template>

<script>
import MediaPlayer from './components/MediaPlayer.vue'

export default {
	name: 'SloneApp',
	components: {
		MediaPlayer
	}
}
</script>

<style>

</style>
