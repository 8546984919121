<template>
	<div class="page">
		<header id="main-header" ref="main-header">
			<div class="container">
				<hgroup role="heading" aria-level="1">
					<h1>Guillaume Chantraine</h1>
					<h2>aka Slone</h2>
				</hgroup>
				<div class="social-networks">
					<ul>
						<li><a target="_blank" rel="noopener" class="youtube" 		href="https://www.youtube.com/@Slone" 									aria-label="La chaîne YouTube de Guillaume Chantraine aka Slone" 	title="La chaîne YouTube de Guillaume Chantraine aka Slone"><i class="fa-brands fa-youtube"></i></a></li>
						<li><a target="_blank" rel="noopener" class="soundcloud" 	href="https://www.soundcloud.com/guillaumechantraine" 					aria-label="La page Soundcloud de Guillaume Chantraine aka Slone" 	title="La page Soundcloud de Guillaume Chantraine aka Slone"><i class="fa-brands fa-soundcloud"></i></a></li>
						<li><a target="_blank" rel="noopener" class="spotify" 		href="https://open.spotify.com/intl-fr/artist/3ICMXMx5ouOWxKmHX1CFVP" 	aria-label="La page Spotify de Guillaume Chantraine aka Slone" 		title="La page Spotify de Guillaume Chantraine aka Slone"><i class="fa-brands fa-spotify"></i></a></li>
						<li><a target="_blank" rel="noopener" class="facebook" 		href="https://www.facebook.com/slonemusic/" 							aria-label="La page Facebook de Guillaume Chantraine" 				title="La page Facebook de Guillaume Chantraine"><i class="fa-brands fa-facebook"></i></a></li>
					</ul>
				</div>
			</div>
		</header>	

		<main id="main-content">
			<div class="container">
				<a href="#slone-player" class="skip-to skip-to-player">Aller aux contrôles du lecteur audio</a>
				<div class="media-playlists" ref="playlist-container" :class="{ active: isPlaylistOpen }">
					<media-playlists-selection
						ref="playlistMenu"
						v-if="playlists"
						:playlists="playlists"
						:visiblePlaylistIndex="visiblePlaylistIndex"
						:activePlaylistIndex="activePlaylistIndex"
						@setActivePlaylistIndex="playPlaylist"
						@setVisiblePlaylistIndex="setVisiblePlaylistIndex"
					/>
					<media-tracklist
						ref="tracklist" 
						v-if="activePlaylist"
						@setActiveTrackById="setActiveTrackById"
						:playlist="visiblePlaylist"
						:activeTrackId="activeTrackId"
					/>
				</div>
			</div>
		</main>

		<footer id="main-footer" ref="main-footer">
			<div class="container">
				<div class="media-interface">
					<audio
						ref="audio"
						id="slone-player" 
						aria-label="audio-player"
						@timeupdate="updateProgressBar"
						@ended="onTrackEnd"
					>
						<source :src="[baseMediaUrl + 'SexyMF_essaiGuitares_01.mp3']" type="audio/mp3" />
						Votre navigateur ne supporte pas l'élément audio.
					</audio>

					<media-progress-bar
						:progress="progress"
						@seek="seekTo"
						role="slider"
						aria-valuemin="0"
						:aria-valuenow="progress"
						aria-valuemax="100"
						tabindex="0"
					/>

					<div class="current-track-title">{{ activeTrackTitle }}</div>

					<media-controls
						@play="playPause"
						@next="nextTrack"
						@prev="prevTrack"
						@loop="toggleLoop"
						@random="toggleRandom"
						@mute="toggleMute"
						@playlists="togglePlaylist"
						@changeVolume="changeVolume" 
						:volume="volume" 
						:isPlaying="isPlaying"
						:isLooped="isLooped"
						:isRandom="isRandom"
						:isMuted="isMuted"
						:isPlaylistOpen="isPlaylistOpen"
						:isLastTrack="isLastTrack"
						:isFirstTrack="isFirstTrack"
					/>
				</div>
				<p class="copyright">Webdesign et développement par <a target="_blank" rel="noopener" href="https://www.chantraine.net" aria-label="Visiter le site de Guillaume Chantraine, développeur web, qui a réalisé ce site">Guillaume Chantraine</a></p>
				<p class="copyright">Photo de <a target="_blank" rel="noopener" href="https://www.thomas-berard.com/" aria-label="Visiter le site de Thomas Bérard, photographe, auteur des photographies qui illustrent ce site">Thomas Bérard</a></p>
				<p class="copyright">&copy; Tous droits réservés</p>
			</div>
		</footer>
	</div>
</template>

<script>
import MediaControls from "./MediaControls.vue";
import MediaProgressBar from "./MediaProgressBar.vue";
import MediaTracklist from "./MediaTracklist.vue";
import MediaPlaylistsSelection from "./MediaPlaylistsSelection.vue";

export default {
	components: {
		MediaControls,
		MediaProgressBar,
		MediaTracklist,
		MediaPlaylistsSelection,
	},
	data() {
		return {
			locale: "fr-FR",
			pageIsLoading: true,
			isPlaying: false,
			isLooped: false,
			isMuted: false,
			isRandom: false,
			isPlaylistOpen: false,
			progress: 0,
			volume: 70,
			playlists: null,
			activeTrackId: null,
			activeTrackIndex: null,
			visiblePlaylistIndex: 0,
			activePlaylistIndex: 0,
			baseMediaUrl: "/media/audio/",
			unplayedTrackIndexes: [],
		};
	},
	mounted() {

		this.getPlaylists().then(
			playlists => {
				this.playlists = playlists;
				this.$nextTick(() => {
					this.initializeSavedSettings();
					this.pageIsLoading = false;
				});

			}
		);

	},
	computed: {
		activeTrackTitle() {
			if (this.activeTrackId) {
				const activeTrackIndex = this.findTrackById(this.activeTrackId).trackIndex;

				const title = this.activePlaylist.tracks[activeTrackIndex].name;
				return title;
			} else return null
		},
		visiblePlaylist() {
			return this.playlists ? this.playlists[this.visiblePlaylistIndex] : 0;
		},
		activePlaylist() {
			return this.playlists ? this.playlists[this.activePlaylistIndex] : 0;
		},
	},

	methods: {
		async getPlaylists() {
			const url = "/playlists.json";
			try {
				const response = await fetch(url);
				if (!response.ok) {
					throw new Error(`HTTP error ${response.status}`);
				}

				const json = await response.json();
				return json;
			} catch (error) {
				console.error(`Erreur lors de l'accès à ${url}:`, error);
				console.table(error);
			}
			return false;
		},
		
		initializeSavedSettings() {
			const storedActivePlaylistIndex = parseInt(localStorage.getItem("activePlaylistIndex"));
			this.activePlaylistIndex = isNaN(storedActivePlaylistIndex) ? 0 : storedActivePlaylistIndex;
			this.visiblePlaylistIndex = this.activePlaylistIndex;

			const storedActiveTrackId = parseInt(localStorage.getItem("activeTrackId"));

			this.activeTrackId = isNaN(storedActiveTrackId) ? this.playlists[this.activePlaylistIndex].tracks[0].id : storedActiveTrackId;

			let trackInfo = this.findTrackById(this.activeTrackId);
			const newTrack = this.playlists[trackInfo.parentPlaylistIndex].tracks[trackInfo.trackIndex];

			if (newTrack) { 
				this.activeTrackIndex = trackInfo.trackIndex;
				if (trackInfo.parentPlaylistIndex !== this.activePlaylistIndex) {
					this.setActivePlaylistIndex(trackInfo.parentPlaylistIndex);
				}
				this.loadTrackInPlayer(newTrack);
			}

			if (localStorage.getItem("isPlaylistOpen")) {
				this.isPlaylistOpen = JSON.parse(localStorage.getItem("isPlaylistOpen"));
			} else this.isPlaylistOpen = true;
		},

		saveSettings() {
			localStorage.setItem("activePlaylistIndex", this.activePlaylistIndex);
			localStorage.setItem("activeTrackId", this.activeTrackId);
			localStorage.setItem("isPlaylistOpen", JSON.stringify(this.isPlaylistOpen));
			localStorage.setItem("locale", this.locale);
		},
		isFirstTrack() {
			return this.playlists[this.activePlaylistIndex] ? this.activeTrackIndex === 0 : true;
		},
		isLastTrack() {
			return this.playlists[this.activePlaylistIndex]	? this.activeTrackIndex === this.playlists[this.activePlaylistIndex].tracks.length - 1	: false;
		},

		setActivePlaylistIndex(index) {
			if (this.playlists[index]) {
				this.activePlaylistIndex = index;
				this.saveSettings();
				return true;
			} else return false;
		},

		playPlaylist(index) {
			if (!index === null) return;
			if (this.setActivePlaylistIndex(index)) {
				const newActiveTrack = this.playlists[index].tracks[0];
				if (newActiveTrack) {
					this.playTrack(this.playlists[index].tracks[0].id);
					this.activeTrackIndex = 0;
					this.saveSettings();
				} else return false;
			}
		},

		setActiveTrackId(id) {
			id = parseInt(id);
			if (!id) return false;
			this.activeTrackId = id;
			return true;
		},
		
		setVisiblePlaylistIndex(index) {
			if (this.playlists[index]) {
				this.visiblePlaylistIndex = index;
			}
			this.saveSettings();
		},

		setActiveTrackById(id) {
			this.playTrack(id);
		},

		playTrack(id) {
			let trackInfo = this.findTrackById(id);
			const newTrack = this.playlists[trackInfo.parentPlaylistIndex].tracks[trackInfo.trackIndex];
			if (newTrack) {
				if (trackInfo.parentPlaylistIndex !== this.activePlaylistIndex) {
					this.setActivePlaylistIndex(trackInfo.parentPlaylistIndex);
				}

				this.activeTrackId = newTrack.id;
				this.loadTrackInPlayer(trackInfo.foundTrack);
				this.activeTrackIndex = trackInfo.trackIndex;
				this.play();
			} else this.stop();
			this.saveSettings();
		},

		stop() {
			const audio = this.$refs.audio;
			audio.pause();
			audio.currentTime = 0;
			this.isPlaying = false;
		},
		pause() {
			const audio = this.$refs.audio;
			audio.pause();
			this.isPlaying = false;
		},
		play() {
			const audio = this.$refs.audio;
			audio.play();
			this.isPlaying = true;
		},
		playPause() {
			const audio = this.$refs.audio;
			if (audio.paused) {
				this.play();
			} else {
				this.pause();
			}
		},



		findTrackById(id) {
			if (!id) return;
			let parentPlaylistIndex, foundTrack, trackIndex;
			let value = parseInt(id);
			this.playlists.forEach((playlist, playlistIndex) => {
				playlist.tracks.find((track, index) => {
					if (track.id === value) {
						parentPlaylistIndex = playlistIndex;
						foundTrack = track;
						trackIndex = index;
						return { foundTrack, trackIndex, parentPlaylistIndex };
					}
				});
			});

			return { foundTrack, trackIndex, parentPlaylistIndex };
		},

		loadTrackInPlayer(track) {
			const audio = this.$refs.audio;
			const baseFolder =
				this.baseMediaUrl + this.playlists[this.activePlaylistIndex]?.folder;

			audio.src = track
				? baseFolder + track.url
				: this.baseMediaUrl + "SexyMF_essaiGuitares_01.mp3";
			
		},

		updateProgressBar() {
			const audio = this.$refs.audio;
			this.progress = (audio.currentTime / audio.duration) * 100;
			if (isNaN(this.progress)) {
				this.progress = 0;	// if the track is not loaded yet, set progress to 0
			}
		},

		seekTo(position) {
			const audio = this.$refs.audio;
			audio.currentTime = audio.duration * (position / 100);
		},

		changeVolume(volume) {
			const audio = this.$refs.audio;
			audio.volume = volume / 100;
			this.volume = volume;
		},

		nextTrack() {
			let newIndex;
			if (this.isRandom) {
				newIndex = this.pickRandomTrackIndex();
			} else if (this.isLastTrack()) {
				if (this.isLooped) newIndex = 0;
				else return;
			} else newIndex = this.activeTrackIndex + 1;

			this.playTrack(this.playlists[this.activePlaylistIndex].tracks[newIndex].id);
		},

		prevTrack() {
			let newIndex;
			if (this.isRandom) {
				newIndex = this.pickRandomTrackIndex();
			} else if (this.isFirstTrack()) {
				if (this.isLooped) newIndex = this.playlists[this.activePlaylistIndex].tracks.length - 1;
				else return;
			} else newIndex = this.activeTrackIndex - 1;

			this.playTrack(this.playlists[this.activePlaylistIndex].tracks[newIndex].id);
		},

		createIndexesBuffer() {
			this.unplayedTrackIndexes = [];
			this.playlists[this.activePlaylistIndex].tracks.forEach((track) => {
				this.unplayedTrackIndexes.push(track.id);
			});
		},

		toggleRandom() {
			this.isRandom = !this.isRandom;
			if (this.isRandom) this.createIndexesBuffer();
		},

		toggleLoop() {
			this.isLooped = !this.isLooped;
		},

		toggleMute() {
			const audio = this.$refs.audio;
			audio.muted = !audio.muted;
			this.isMuted = audio.muted;
		},

		togglePlaylist() {
			this.isPlaylistOpen =!this.isPlaylistOpen;
			this.saveSettings();
		},

		onTrackEnd() {
			this.nextTrack();
		},

		pickRandomTrackIndex() {
			const randomIndex = Math.floor(Math.random() * this.unplayedTrackIndexes.length);
			this.unplayedTrackIndexes.splice(randomIndex, 1);
			return randomIndex;
		},


	},
};
</script>
